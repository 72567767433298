import React from "react";
import {
  FaTwitterSquare,
  FaYoutubeSquare,
  FaLinkedin,
  FaGithubSquare,
} from "react-icons/fa";

const SocialLinks = (props)  => {
  let hoverStyle = "hover:text-blue-600";
  if (props.dark) {
    hoverStyle = "hover:text-gray-300";
  }

  return (
    <ul className="flex items-center space-x-4">
      <li className={`text-gray-500 ${hoverStyle}`}>
        <a href="https://twitter.com/_jgoebel">
          <FaTwitterSquare className="text-4xl" />
        </a>
      </li>
      <li className={`text-gray-500 ${hoverStyle}`}>
        <a href="https://www.youtube.com/channel/UCFOmyIxUSbCqTLWoa4IODxw">
          <FaYoutubeSquare className="text-4xl" />
        </a>
      </li>
      <li className={`text-gray-500 ${hoverStyle}`}>
        <a href="https://www.linkedin.com/in/jan-goebel-15113b90/">
          <FaLinkedin className="text-4xl" />
        </a>
      </li>
      <li className={`text-gray-500 ${hoverStyle}`}>
        <a href="https://github.com/jangbl">
          <FaGithubSquare className="text-4xl" />
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;
