import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import { SEO } from "../components/SEO";

const Privacy = () => {
  return (
    <Layout>
      <h1 className="font-extrabold text-4xl mt-8">Privacy</h1>
      <p className="text-gray-500 mb-8">Last updated: Dec 19 2020</p>
      <h2 className="font-extrabold text-3xl mt-8">1. Basics</h2>
      <p className="mb-2 text-gray-500">
        This privacy statement is intended to inform users of this website about
        the degree to which (personal) data is collected and used by the website
        owner (we, website’s operator, service provider). Data is considered
        personal if you can be identified by it.
      </p>
      <p className="mb-2 text-gray-500">
        The website’s operator is taking your privacy very seriously and
        conforms to to international privacy laws, including the European GDPR
        privacy protection law. Due to the fast moving pace of the technology
        industry, we recommend reviewing this privacy statement regularly.
      </p>
      <p className="mb-2 text-gray-500">
        Definitions of used terms can be found in Article 4 GDPR.
      </p>
      <h3 className="font-extrabold text-xl">
        Who is responsible for privacy of this website?
      </h3>
      <p className="mb-2 text-gray-500">
        The website’s operator is responsible for the data processing and
        collection. The contact details can be found in the{" "}
        <Link className="text-blue-500" to="/imprint">
          imprint
        </Link>
        .
      </p>
      <h3 className="font-extrabold mt-4 text-xl">How do we collect data?</h3>
      <p className="mb-2 text-gray-500">
        Some data is collected because you as a user explicitly give us this
        information. This is the case if you enter data into a contact form.
      </p>
      <p className="mb-2 text-gray-500">
        Some data is also collected automatically once to visit our website.
        Typically this is technical data (browser type, operating system, date
        when you visit the website). The collection of this data happens
        automatically.
      </p>
      <h3 className="font-extrabold mt-4 text-xl">
        What is the purpose of collecting data?
      </h3>
      <p className="mb-2 text-gray-500">
        Some data is used to ensure the correct and smooth functionality of this
        website. Other data is collected to analyse user behaviour.
      </p>
      <h3 className="font-extrabold mt-4 text-xl">
        What rights do you have regarding your data?
      </h3>
      <p className="mb-2 text-gray-500">
        You have to right to ask for the origin, recipient and purpose of your
        personal data free of charge. You also have the right to ask for the
        correction, locking or deletion of this data. Feel free to reach out to
        us via the contact details given in the legal section. In addition you
        have the right to report issues to regulatory authorities.
      </p>
      <p className="mb-2 text-gray-500">
        Under certain circumstances you can demand the restriction of the
        processing of your personal data. Please refer to the ‘Right to restrict
        data processing’ section in this privacy statement.
      </p>
      <h3 className="font-extrabold mt-4 text-xl">
        Usage of tracking tools from third party providers?
      </h3>
      <p className="mb-2 text-gray-500">
        When visiting this webpage your behaviour on this website can be
        analysed for statistical purposes. This is done by using cookies
        together with so called analytics tools. The analysis of your behaviour
        on the page is typically done anonymously, i.e. your behaviour cannot be
        traced back to you as a person. You can opt out of this analysis by
        using specific tools. You will find more detailed information on that in
        this privacy statement.
      </p>
      <h2 className="font-extrabold text-3xl mt-8">
        2. General and obligatory Information
      </h2>
      <p className="mb-2 text-gray-500">
        This website’s operator is taking the protection of your personal data
        very seriously. We treat your personal data confidentially and comply
        with regulatory data protection laws and this privacy statement.
      </p>
      <p className="mb-2 text-gray-500">
        If you visit this website, various personal data is collected. Data is
        considered personal if you as a person can be identified by it (e.g.
        email address). This privacy statements specifies to what extent we
        collect data and for what purpose.
      </p>
      <p className="mb-2 text-gray-500">
        We would like to point out that data transfers on the internet (e.g. via
        email) may contain security vulenrnabilities and that we cannot
        guarantee for the complete safety of data transfers in general.
      </p>
      <h3 className="font-extrabold mt-4 text-xl">
        Responsible for data processing
      </h3>
      <p className="mb-2 text-gray-500">
        Please refer to the&nbsp;
        <Link className="text-blue-500" to="/imprint">
          imprint
        </Link>
      </p>
      <h3 className="font-extrabold mt-4 text-xl">
        Right to objection for data processing
      </h3>
      <p className="mb-2 text-gray-500">
        If the data processing is based on Article 6 (1) GDPR you have the right
        to enter an objection due to the special situation you’re currently in.
        This is also true for profiling.
      </p>
      <p className="mb-2 text-gray-500">
        If you enter an objection, we will no longer process you personal data
        unless we have legitimate interests to do so that prevail you interests.
        (Article 21 (1) GDPR).
      </p>
      <p className="mb-2 text-gray-500">
        If personal data is processed to do direct advertisment, you have to
        right to enter an objection against the processing of you personal data
        (also true for profiling). If you enter an objection, your personal data
        will not longer be used for direct advertisement (Article 21 (2) GDPR).
      </p>
      <p className="mb-2 text-gray-500">
        In case of violating the terms specified in the GDPR, users can contact
        the responsible controlling authority. The responsible controlling
        authority typically sits in your country of residence
      </p>
      <h3 className="font-extrabold mt-4 text-xl">Right to data migration</h3>
      <p className="mb-2 text-gray-500">
        You have the right to obtain your personal data we have collected with
        your consent in a common machine readable format. If you request the
        direct transfer of data to a third party, this can only be done if it is
        technically feasible.
      </p>
      <h3 className="font-extrabold mt-4 text-xl">SSL / TLS encryption</h3>
      <p className="mb-2 text-gray-500">
        For security reasons, this website always uses SSL / TLS encryption to
        further protect your data. You can determine whether the connection is
        encrypted by checking if the current URL starts with ‘https://’. If
        TLS/SSL encryption is enabled, data that you send to us cannot be read
        by third parties.
      </p>
      <h3 className="font-extrabold mt-4 text-xl">
        Information, locking, deletion and correction
      </h3>
      <p className="mb-2 text-gray-500">
        You have the right to obtain information on your stored personal data,
        its origin, its recipient and the data processing’s purpose and if
        applicable the right to correction, locking or deletion of these files.
      </p>
      <p className="mb-2 text-gray-500">
        You can reach out to us via the contact information provided in the
        imprint.
      </p>
      <h3 className="font-extrabold mt-4 text-xl">
        Information, locking, deletion and correction
      </h3>
      <p className="mb-2 text-gray-500">
        You have the right to ask for restricting the processing of your
        personal data. You can reach out to us via the contact information
        provided in the imprint. The right to restrict data processing is
        possible in the following cases:
      </p>
      <ul className="list-disc pl-4">
        <li>
          <p className="text-gray-500">
            if you doubt the correctness of your personal data, we typically
            need time to check this. During the process of checking your data,
            you have the right to restrict the data processing of your personal
            data.
          </p>
        </li>
        <li>
          <p className="text-gray-500">
            if the processing of your personal data did not happen with your
            consent, you can ask for the restriction of the data processing
            instead of its deletion.
          </p>
        </li>
        <li>
          <p className="text-gray-500">
            if we no longer need your personal data, but you need it to defend
            yourself in front of a court, you can demand the restriction of the
            data processing.
          </p>
        </li>
        <li>
          <p className="text-gray-500">
            if you enter an objection due to article 21 GDPR, your interests and
            our interests have to be balanced. As long as it is not clear whose
            interests are prevalent, you have the right to restrict the
            processing of your personal data.
          </p>
        </li>
        <li>
          <p className="text-gray-500">
            If you have restricted the processing of your personal data, this
            data may only be used to defend yourself in court, to protect your
            rights or the rights of another person or to protect a legitimate
            public interest of the European union or other member states.
          </p>
        </li>
      </ul>
      <h3 className="font-extrabold mt-4 text-xl">
        Objection against advertising emails
      </h3>
      <p className="mb-2 text-gray-500">
        We hereby object the usage of the contact information specified in the
        imprint for advertisement purposes. You may only send advertisement if
        we explicity agreed to that. We reserve the right to take legal measures
        if the contact data is used for advertisement (e.g. spam).
      </p>
      <h2 className="font-extrabold text-3xl mt-8">
        3. Data Collection on this webpage
      </h2>
      <p className="mb-2 text-gray-500">
        This website’s operator only collects, uses and forwards your personal
        data if this is compliant with data protection laws (including GDPR) and
        only if you agree. All data that is used to identify you as a person is
        considered personal data, e.g. your email / name.
      </p>
      <p className="mb-2 text-gray-500">
        You can visit this website without providing personal data as well. To
        improve our online service, we save your access data on our website
        (non-personal data). Access data includes the name of requested files or
        your internet provider’s name. This is anonymous data so you are not
        directly identifiable.
      </p>
      <h3 className="font-extrabold mt-4 text-xl">Accessing this webpage</h3>
      <p className="mb-2 text-gray-500">
        The website’s owner is processing website access data because of
        legitimate interests (GDPR Article 6(1)). The website accesses data is
        saved as server log files. The following data is collected:
      </p>
      <ul className="list-disc pl-4">
        <li>
          <p className="text-gray-500">time of website access</p>
        </li>
        <li>
          <p className="text-gray-500">visited website</p>
        </li>
        <li>
          <p className="text-gray-500">amount of data sent in byte</p>
        </li>
        <li>
          <p className="text-gray-500">used browser</p>
        </li>
        <li>
          <p className="text-gray-500">used operating system</p>
        </li>
        <li>
          <p className="text-gray-500">used ip address</p>
        </li>
      </ul>
      <p className="mb-2 text-gray-500">
        The server log files are saved for a maximum of seven days and will be
        deleted within seven days. This data is saved for to security reasons,
        i.e. to elucidate misuse. If data has to be kept as a piece of evidence,
        it will not not be deleted until the issue has been resolved.
      </p>
      <h3 className="font-extrabold mt-4 text-xl">Cookies</h3>
      <p className="mb-2 text-gray-500">
        This website uses cookies for pseudo-anonymized reach measurement that
        are either sent from our server or a third party server to the user’s
        browser. Cookies are pieces of data that are saved on your device.
        Cookies help to improve this website’s usability and security.
      </p>
      <p className="mb-2 text-gray-500">
        If you do not wish to participate in the reach measurements you can opt
        out via the following websites:
      </p>
      <ul className="list-disc pl-4">
        <li>
          <p className="mb-2 text-gray-500">
            Cookie deactivation site for networkadertising.org:&nbsp;
            <a
              className="text-blue-500"
              href="http://optout.networkadvertising.org/?c=1#!/"
            >
              http://optout.networkadvertising.org/?c=1#!/
            </a>
          </p>
        </li>
        <li>
          <p className="mb-2 text-gray-500">
            Cookie deactivation website of American websites:&nbsp;
            <a
              className="text-blue-500"
              href="http://optout.aboutads.info/?c=2#!/"
            >
              http://optout.aboutads.info/?c=2#!/
            </a>
          </p>
        </li>
        <li>
          <p className="mb-2 text-gray-500">
            Cookie deactivation website of European websites:&nbsp;
            <a
              className="text-blue-500"
              href="http://optout.networkadvertising.org/?c=1#!/"
            >
              Cookie deactivation website for European website:
            </a>
          </p>
        </li>
      </ul>
      <p className="mb-2 text-gray-500">
        Common browsers allow you to deactivate cookies in their settings.
        However, bear in mind that we cannot gurantee the functionality of this
        website if you deactivate your cookies.
      </p>
      <h3 className="font-extrabold mt-4 text-xl">Google Analytics</h3>
      <p className="mb-2 text-gray-500">
        This website is using a tool called Google Analytics due to the
        legitimate interest (GDPR Article 6 (1)) to optimize this website’s
        online offer. Google Analytics is offered by Google Inc (1600
        Amphitheatre Parkway Mountain View, CA 94043, USA). This service (Google
        Analytics) uses cookies which are saved on your device. The information
        collected by these cookies are typically transferred and saved to Google
        servers located in the US.
      </p>
      <p className="mb-2 text-gray-500">
        Google LLC complies with the European data protection law GDPR and is
        certified as part of the privacy-shieled arrangement:{" "}
        <a
          className="text-blue-500"
          href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"
        >
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
        </a>
      </p>
      <p className="mb-2 text-gray-500">
        This website uses a technique called IP masking. The IP addresses from
        users within the EU, European Economic area and countries that have
        ratified the GDPR, are masked. In very few instances, the IP address is
        being sent unmasked to a Google server in the US where it is masked. By
        masking the IP address, the IP address is no considered personal data
        any more according to GDPR since users cannot be uniquely identified by
        their masked IP address any more. The IP address from the user’s browser
        is not combined with other data that Google has already stored.
      </p>
      <p className="mb-2 text-gray-500">
        As part of the data processing agreement we have created with Google,
        Google is creating a report on the website’s usage and activity. The
        data collected by Google under our data processing agreement are used to
        analyze the usage of our web service.
      </p>
      <p className="mb-2 text-gray-500">
        You can prevent the storage of cookies on your device if you specify the
        respective settings in your browser. However, we cannot guarantee this
        website’s functionality if your browser doesn’t accept cookies.
      </p>
      <p className="mb-2 text-gray-500">
        In addition, you can install a browser plugin that prevents your usage
        data from being sent and used by Google.
      </p>
      <p className="mb-2 text-gray-500">
        You can get the plugin by visiting the following link:
        <a
          className="text-blue-500"
          href="https://tools.google.com/dlpage/gaoptout?hl=de"
        >
          https://tools.google.com/dlpage/gaoptout?hl=de
        </a>
      </p>
      <p className="mb-2 text-gray-500">
        As an alternative you can prevent Google Analytics from collecting your
        data by clicking on this button:
      </p>
      <button
        className="text-white bg-indigo-600 hover:bg-indigo-700 border-transparent mt-3 mr-4 w-full inline-flex items-center justify-center px-4 py-2 shadow-sm font-medium border  rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 mb-3 sm:w-auto"
        onClick={() => {
          document.cookie =
            "ga-disable-UA-178464201-1=true; expires=Thu, 31 Dec 2099 23:59:59 UTC;path=/";
          window.disableStr = 1;
          alert("opt out successful");
        }}
      >
        Opt out of Google Analytics
      </button>
      <p className="mb-2 text-gray-500">
        By clicking this opt out-button you download an opt-out-cookie.
        Therefore your browser must allow the storage of cookies. If you delete
        your cookies regularly you have to click this opt out link again to
        prevent Google Analytics from taking action.
      </p>
      <p className="mb-2 text-gray-500">
        You can find more information on how Google is using your data under the
        following links:
      </p>
      <ul className="list-disc pl-4">
        <li>
          <p className="mb-2 text-gray-500">
            Cookie deactivation site for networkadertising.org:
            <a
              className="text-blue-500"
              href="http://optout.networkadvertising.org/?c=1#!/"
            >
              http://optout.networkadvertising.org/?c=1#!/
            </a>
          </p>
        </li>
        <li>
          <p className="mb-2 text-gray-500">
            Cookie deactivation website of American websites:
            <a
              className="text-blue-500"
              href="http://optout.aboutads.info/?c=2#!/"
            >
              http://optout.aboutads.info/?c=2#!/
            </a>
          </p>
        </li>
        <li>
          <p className="mb-2 text-gray-500">
            Cookie deactivation website of European websites:
            <a
              className="text-blue-500"
              href="http://optout.networkadvertising.org/?c=1#!/"
            >
              Cookie deactivation website for European website:
            </a>
          </p>
        </li>
      </ul>
      <h3 className="font-extrabold mt-4 text-xl">Twitter Plugin</h3>
      <p className="mb-2 text-gray-500">
        This website may use functionality that Twitter Inc (1355 Market Street,
        Suite 900, San Francisco, CA 94103, USA) provides. By using Twitter and
        by using the retweet functionality, the websites you visit are connected
        to your twitter account and shared with other users. In the process of
        doing so, data is transferred to twitter. We would like to point out
        that we don’t know exactly what kind of data is transferred to Twitter
        and to what extent it is used. You can find more information on this in
        Twitter’s privacy statement:{" "}
        <a className="text-blue-500" href="https://twitter.com/de/privacy">
          https://twitter.com/de/privacy
        </a>
      </p>
      <p className="mb-2 text-gray-500">
        The usage of the Twitter plugin is based on Article 6 (1) GDPR. This
        website’s operator has a legitimate interest create a bigger visibility
        in social media. You can review an change your privacy settings in your
        account settings: &nbsp;
        <a
          className="text-blue-500"
          href="https://twitter.com/account/settings"
        >
          https://twitter.com/account/settings
        </a>
      </p>
      <h3 className="font-extrabold mt-4 text-xl">Google Web Fonts</h3>
      <p className="mb-2 text-gray-500">
        For aesthetic reasons, this website may use Google Web Fonts, a service
        offered by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
        94043, USA. When you visit our webpage, your browser loads the required
        web fonts in your browser cache to display the different fonts properly.
      </p>
      <p className="mb-2 text-gray-500">
        To load these fonts, your browser has to establish a connect to Google
        servers and therefore Google knows that our website has been accessed by
        your IP address. The website’s operator has a legitimate interest to
        provide a great web presence and therefore we use Google Web Fonts.
      </p>
      <p className="mb-2 text-gray-500">
        If your browser does not support Google Web fonts, a standard font from
        your computer is used. You can find more information on Google Web fonts
        here:
        <a
          className="text-blue-500"
          href="https://developers.google.com/fonts/faq"
        >
          https://developers.google.com/fonts/faq
        </a>
        &nbsp; and we also encourage you to review their privacy policy: &nbsp;
        <a className="text-blue-500" href="https://policies.google.com/privacy">
          https://policies.google.com/privacy
        </a>
        .
      </p>
      <h3 className="font-extrabold mt-4 text-xl">Youtube</h3>
      <p className="mb-2 text-gray-500">
        Your website may use plugins from Youtube LLC, 901 Cherry Ave., San
        Bruno, CA 94066, USA.
      </p>
      <p className="mb-2 text-gray-500">
        If you visit one of our pages that uses the youtube plugin, the plugin
        will connect with Youtube servers and communicate which webpage you have
        visited. Furthermore Youtube saves various cookies on your device. By
        storing cookies on your device, Youtube can collect information on the
        visitors of our webpage. This information is collected to create
        statistics about videos (view count, view duration), to improve
        usability and to prevent misuse. The cookies remain on your device until
        you delete them.
      </p>
      <p className="mb-2 text-gray-500">
        If you are logged in with your Youtube account you enable Youtube to
        connect your behaviour on our website with your Youtube account. You can
        prevent that from happening by logging out of your Youtube account
        before you visit this website.
      </p>
      <p className="mb-8 text-gray-500">
        The website’s owner has a legitimate interest to leverage Youtube’s
        plugin (Article 6 (1) GDPR) because it is in our interest to build an
        audience on social media. You can find more information on how Youtube
        deals with your data in its privacy statement:
        <a className="text-blue-500" href="https://policies.google.com/privacy">
          https://policies.google.com/privacy
        </a>
      </p>
    </Layout>
  );
};

export const Head = () => <SEO
  pathname="/privacy"
  title="Privacy"
  description="Privacy statement for jangoebel.com"
/>

export default Privacy;
