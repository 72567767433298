import React from "react";
import { Link } from "gatsby";
import logo from "../images/logo.svg";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  let mobileNavClass = "hidden";
  let navbarIcon = <FaBars />;
  if (isOpen) {
    navbarIcon = <FaTimes />;
    mobileNavClass = "flex";
  }

  return (
    <nav className="pt-4 pb-2 mb-8 bg-white shadow px-4">
      <div className="max-w-screen-xl mx-auto my-1">
        <div className="flex flex-row justify-between items-center mr-2">
          <div className="sm:hidden">
            <span className="sr-only">Open main menu</span>
            <button className="text-3xl ml-2 text-gray-700" onClick={toggle}>
              {navbarIcon}
            </button>
          </div>
          <Link to="/" className="hidden sm:block hover:cursor-pointer">
            <img
              src={logo}
              style={{ height: "40px" }}
              alt="logo"
            />
          </Link>

          <ul className="hidden sm:flex space-x-8">
            <li className="text-lg text-gray-700 hover:underline hover:text-gray-900">
              <Link to="/essays">All Essays</Link>
            </li>
            <li className="text-lg text-gray-700 hover:underline hover:text-gray-900">
              <Link to="/about">About</Link>
            </li>
          </ul>
        </div>
        <div
          className={`${mobileNavClass} ml-2 mt-4 flex-col space-y-6 sm:hidden`}
        >
          <Link to="/" className="text-lg hover:underline">
            Home
          </Link>
          <Link to="/essays" className="text-lg hover:underline">
            All Essays
          </Link>
          <Link to="/about" className="text-lg hover:underline">
            About
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
