import React from "react";
import SocialLinks from "./SocialLinks";
import { Link } from "gatsby";

const Footer = () => {
  return (
    // uses global site-footer CSS class in case content is not long enough
    // check caveats in Readme
    <footer className="site-footer bg-gray-800 py-4 px-4">
      <div className="max-w-screen-xl mx-auto flex justify-between items-center">
        <div className="flex">
          <p className="hidden sm:block text-gray-400">© Jan Goebel</p>
          <Link
            className="text-gray-400 hover:text-gray-300 hover:underline sm:ml-10"
            to="/privacy"
          >
            Privacy
          </Link>
          <Link
            className="text-gray-400 hover:text-gray-300 hover:underline ml-10"
            to="/imprint"
          >
            Imprint
          </Link>
        </div>
        <SocialLinks dark={true} />
      </div>
    </footer>
  );
};

export default Footer;
