import React from "react";

import Footer from "./Footer";
import Navbar from "./Navbar";

export default function Layout({ children }) {
  return (
    <>
      <Navbar />
      {/* uses page-wrap global CSS class for footer. Check caveats in Readme */}
      <div className="page-wrap bg-gray-50 pb-8 px-4">
        <main className="max-w-screen-xl mx-auto">{children}</main>
      </div>
      <Footer />
    </>
  );
}
